.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.multiple-value-text-input input {
    width: 100%;
    max-width: 500px !important;
    padding: 10px !important;
    border-radius: 5px !important;
    border: 1px solid #eee !important;
    outline: none !important;
}

.multiple-value-text-input label{
  padding: 10px 0px !important;

}
.multiple-value-text-input .multiple-value-text-input-item {
    padding: 8px !important;
    background: #eaebec !important;
    color: #000;
    margin: 5px 0px !important;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px !important;



}
.multiple-value-text-input-item-container p {
  padding: 10px 0px !important;
  display: grid !important;
  grid-template-columns: repeat(6, 1fr) !important;
  gap: 10px !important;
  
}

.import-modal-container{
  position: absolute;
    right: 200px;
    top: 59px;
}
.admin-container{
  position: absolute;
    right: 110px;
    top: 59px;
    cursor: pointer;
}
.button{
width: 200px !important;

}
.actions__container{
  display :  flex;
  align-items: center;
  justify-content: space-between;
}

.otp-container{
  width: 100%;
  
  padding: 20px 0;
  
}
.cursor-none{
  cursor: no-drop !important;
}
.btn:disabled{
  cursor: no-drop !important;
  background: gray !important;

  border: gray !important;
}
.opt-inputs{
  width: 50px !important;
  
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #eee;
  outline: none;

}
.report__selection{
  padding: 10px;
  width: 300px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid rgb(198, 196, 196);
  

}


/* shimmer */

 .shimmer__card .shimmerBG {
	 animation-duration: 2.2s;
	 animation-fill-mode: forwards;
	 animation-iteration-count: infinite;
	 animation-name: shimmer;
	 animation-timing-function: linear;
	 background: #ddd;
	 background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
	 background-size: 1200px 100%;
}

@media (max-width: 769px) {
 .shimmer__card ,.shimmerBG{
  display: none;
 }

}
 @-webkit-keyframes shimmer {
	 0% {
		 background-position: -100% 0;
	}
	 100% {
		 background-position: 100% 0;
	}
}
 @keyframes shimmer {
	 0% {
		 background-position: -1200px 0;
	}
	 100% {
		 background-position: 1200px 0;
	}
}
 .shimmer__card .media {
	 height: 200px;
}
 .shimmer__card .p-32 {
	 padding: 32px;
}
 .shimmer__card .title-line {
	 height: 24px;
	 width: 100%;
	 margin-bottom: 12px;
	 border-radius: 20px;
}
 .shimmer__card .content-line {
	 height: 8px;
	 width: 100%;
	 margin-bottom: 16px;
	 border-radius: 8px;
}
 .shimmer__card .end {
	 width: 40%;
}
 .m-t-24 {
	 margin-top: 24px;
}

.modal__header_tiltle_container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit{
  position: relative;
  top : -8px;
  cursor: pointer;
}
.names {
  color: #0b0b0b;
  font-size: 50px;
  text-transform: uppercase;
  transition: 0.5s all;
  border: 5px dotted #e3c70e;
  width: 500px;
  text-align: center;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}
 