/* 
.store_shimmer__container{


}








lines {
  height: 10px;
  margin-top: 10px;
  width: 200px; 
}

photo {
  display: block!important;
  width: 325px; 
  height: 100px; 
  margin-top: 15px;
}


 */

 .shimmer__container{
        width: 100%;
        height: 100%;
        display : grid;
        gap: 60px;

 }

 .heading_line{
        max-width: 500px;
        width: 100%;
        height: 30px;
        
 }
 .search{
        width: 100vw;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;




 }
 .results__group{ 
        max-width: 1250px;
        width: 100%;
        height: 500px;

 }

 .shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f5f8fc 0%, #f0f1f3 20%, #f6f7f8 40%, #f3f6f9 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  }


  @keyframes placeholderShimmer {
        0% {
        background-position: -468px 0;
        }
        100% {
        background-position: 468px 0;
        }
        }


 @-webkit-keyframes placeholderShimmer {
        0% {
                background-position: -468px 0;
                }
        100% {
                background-position: 468px 0;
                }
        }
